<template>
    <div>
        <ViewTitle title="商品資料庫 | 有效契約利益計算" />
        <ProductFilter>
            <template v-slot:filter-header>
                <div class="header">
                    <!-- TODO: 開放一個月以上查詢後更換 -->
                    <!-- <DateRangeTextbox
                        v-if="true"
                        title="查詢工作月"
                        startName="commitDateStart"
                        endName="commitDateEnd"
                        :required="true"
                        :isOnlyMonth="true"
                        :hasLimit="true"
                        min="2022"
                        max="today"
                        v-model:startDate="filterPayload.commitDateStart"
                        v-model:endDate="filterPayload.commitDateEnd"
                    /> -->
                    <Textbox
                        title="查詢工作月"
                        type="date"
                        min="2020-01"
                        :onlyMonth="true"
                        :required="true"
                        :isMonthLastDay="true"
                        v-model="filterPayload.commitDateEnd"
                    />
                    <Button
                        @click.prevent="showDataTable"
                        buttonWord="套用"
                        buttonStyle="blue"
                        :disabled="isTableLoading"
                        iconClass="big-icon search-icon"
                    />
                </div>
            </template>
        </ProductFilter>
        <ProductTable
            excelTitle="商品資料庫_有效契約利益計算"
            :filterTitles="filterTitles"
            :listData="listData"
            :isLoading="isTableLoading"
            :totalData="totalData"
            :filterLog="filterLog"
        />
        <ProductMobileAlertModal v-model="isShowMobileAlert" />
    </div>
</template>

<script>
// import _ from 'lodash'
import ViewTitle from '@/components/ViewTitle.vue'
import ProductTable from '@/containers/product/ProductTable.vue'
import ProductFilter from '../../containers/product/ProductFilter.vue'
// import DateRangeTextbox from '../../components/DateRangeTextbox.vue'
import Button from '../../components/Button.vue'
import ProductMobileAlertModal from '@/containers/product/ProductMobileAlertModal.vue'
import { getProductContractAPI } from '@/assets/javascripts/api'
import { productContractFilterTitles } from '@/assets/javascripts/product/setting'
import _ from 'lodash'
import Textbox from '@/components/Textbox.vue'

export default {
    name: 'ProductContract',
    components: {
        ViewTitle,
        ProductTable,
        ProductFilter,
        // DateRangeTextbox,
        Button,
        ProductMobileAlertModal,
        Textbox
    },
    methods: {
        showDataTable: async function () {
            if (
                !this.testInput() ||
                _.isEqual(this.filterPayload, this.filterLog)
            )
                return
            const payload = _.cloneDeep(this.filterPayload)
            this.$showLoading()
            this.isTableLoading = true
            this.filterLog = payload
            try {
                const res = await this.$getResponse(
                    payload,
                    {
                        apiAlias: 'list',
                        apiName: '此報表'
                    },
                    getProductContractAPI
                )
                this.listData = res.data.products
                this.totalData = _.omit(res.data, 'products')
            } catch (e) {
                if (e?.message === 'noPermission') {
                    this.$showErrorMessage(
                        '此帳號尚無查看此報表的權限',
                        '請確認您的帳號權限，如有疑問請洽飛鴿'
                    )
                } else {
                    this.$showErrorMessage(
                        '查詢時出現錯誤，請稍後再試',
                        '若持續出現此問題，請聯繫資訊客服'
                    )
                }
                this.listData = []
            } finally {
                this.isTableLoading = false
                this.$hideLoading()
            }
        },
        testInput: function () {
            let formValid = true
            let input = document.querySelectorAll('.header input.textbox')
            input.forEach((column) => {
                if (!column.checkValidity()) {
                    column.parentNode.classList.add('input-error-ci-style')
                    formValid = false
                }
            })
            return formValid
        },
        resetFilter() {
            // TODO: 開放查詢多月後調整
            const now = this.$formatDate(new Date())
            // let start = now.split('-')
            // start[0] = start[0] - 1
            // start = start.join('-')
            // this.filterPayload = {
            //     commitDateStart: now,
            //     commitDateEnd: now
            // }
            this.filterPayload.commitDateEnd = now
        },
        beforeunloadHandler(e) {
            e.returnValue = ''
            e.preventDefault()
            return ''
        }
    },
    computed: {
        filterTitles() {
            return productContractFilterTitles
        }
    },
    watch: {
        'filterPayload.commitDateEnd': {
            // TODO: 開放查詢多月後刪除
            handler(val) {
                let dateArr = val.split('-')
                dateArr[2] = '01'
                this.filterPayload.commitDateStart = dateArr.join('-')
            }
        },
        isTableLoading(val) {
            if (val) {
                window.addEventListener(
                    'beforeunload',
                    this.beforeunloadHandler
                )
            } else {
                window.removeEventListener(
                    'beforeunload',
                    this.beforeunloadHandler
                )
            }
        }
    },
    data() {
        return {
            listData: [],
            totalData: {},
            isTableLoading: false,
            filterPayload: {
                commitDateStart: '',
                commitDateEnd: ''
            },
            filterLog: {},
            isShowMobileAlert: false
        }
    },
    mounted() {
        this.resetFilter()
    },
    async created() {
        this.isShowMobileAlert = this.$isMobile() && !this.$isMobileHorizontal()
    }
}
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

:deep(.button) {
    margin-top: 10px;
}

.filter-container {
    :deep(.input-container) {
        margin-bottom: 5px;
        margin-right: 20px;
        .textbox {
            background-color: $primary-white;
            border: 1px solid $sum-row-grey;
        }
    }
    :deep(.total-text) {
        display: none;
    }
    :deep(.search) {
        display: none;
    }
}

.table-wrapper {
    border: none;
    padding-top: 0;
    :deep(.guide-container) {
        .pc-search-img,
        .mobile-search-img {
            display: none;
        }
        .mobile span {
            display: none;
        }
    }
}
</style>
